import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import "./Style/Splash.css"

const Splash = () => {
  // const logo = document.querySelectorAll('#logoBlock path')

  // for (let i = 0; i < logo.length; i++) {
  //   console.log(`Letter ${i} is ${logo[i].getTotalLength()}`)
  // }
  const moveUp = () => {
    var t1 = gsap.timeline().to("#logo", {
      ease: "slow(0.7, 0.7, false)",
      duration: 5,

      y: -40
    })
    return t1
  }
  const small = () => {
    var t2 = gsap.timeline().to("#logo", {
      ease: "slow(0.7, 0.7, false)",
      duration: 5,
      scale: .8
    })
    return t2
  }
  const title = () => {
    var t3 = gsap.timeline().to(".text", {
      ease: "power1.out",
      y: 0,
      stagger: .2,
      duration: 1,

    })
    return t3
  }
 

  useEffect(() => {
    var master = gsap.timeline()
    master.add(moveUp())
      .add(small(), "-=5")
      .add(title(), "-=2.5")
  }, [])

  return (

    <div id="home">
      <a href="#about">
        <div className="top">
       



          <svg id="logo" viewBox="0 0 233 143" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.2309 6.49822L19.2273 6.50032C13.4581 9.92289 8.98029 14.8171 5.79447 21.167C2.6087 27.5168 0.988781 34.8894 0.918993 43.2714C0.850963 51.4423 2.34965 58.7313 5.42845 65.1266L5.42995 65.1296C8.61402 71.63 13.0593 76.6959 18.7669 80.3114C24.4779 83.929 31.063 85.7598 38.5061 85.8159L112.424 86.373C113.091 86.378 113.652 86.1143 114.067 85.6017C114.586 85.1949 114.859 84.6381 114.864 83.9713L115.052 61.4121C115.058 60.7283 114.783 60.1235 114.28 59.6126C113.854 59.1791 113.293 58.9787 112.652 58.9739L38.8942 58.418C34.534 58.3852 31.0564 56.9632 28.4127 54.1756C25.8692 51.3825 24.6019 47.8213 24.6383 43.4502C24.6766 38.8574 26.0072 35.3282 28.5772 32.7966C31.2629 30.2566 34.7674 28.9861 39.139 29.019L48.4187 29.089C49.0855 29.094 49.6465 28.8304 50.0616 28.3178C50.5807 27.9109 50.8532 27.3541 50.8588 26.6873L51.0479 3.96809C51.0536 3.28433 50.7792 2.67949 50.2765 2.16868C49.8497 1.73508 49.2886 1.53477 48.6482 1.52994L39.2084 1.4588C31.7681 1.40272 25.1042 3.07888 19.2309 6.49822Z" />
            <path d="M214.491 137.004L214.494 137.002C220.213 133.504 224.62 128.552 227.713 122.161C230.807 115.77 232.32 108.377 232.268 99.9946C232.218 91.8235 230.614 84.5549 227.443 78.2006L227.441 78.1975C224.164 71.7395 219.645 66.7324 213.886 63.192C208.123 59.6496 201.512 57.9053 194.069 57.9468L120.15 58.3586C119.483 58.3623 118.926 58.6333 118.519 59.1513C118.005 59.5649 117.741 60.1252 117.745 60.792L117.884 83.3516C117.888 84.0354 118.171 84.6365 118.681 85.1407C119.114 85.5687 119.678 85.7616 120.319 85.7581L194.078 85.3471C198.438 85.3228 201.936 86.6991 204.619 89.4518C207.203 92.2113 208.522 95.7555 208.549 100.127C208.577 104.72 207.298 108.266 204.765 110.831C202.116 113.406 198.63 114.722 194.259 114.747L184.979 114.798C184.312 114.802 183.755 115.073 183.347 115.591C182.834 116.005 182.569 116.565 182.574 117.232L182.713 139.951C182.718 140.635 183.001 141.236 183.511 141.74C183.944 142.168 184.508 142.361 185.148 142.358L194.588 142.305C202.029 142.264 208.667 140.5 214.491 137.004Z" />

            <path d="M65.8047 35H69.6016V46.7539C69.6016 47.8945 69.3438 48.8789 68.8281 49.707C68.3203 50.5352 67.6289 51.1758 66.7539 51.6289C65.8789 52.0742 64.8945 52.2969 63.8008 52.2969C62.6836 52.2969 61.6875 52.1055 60.8125 51.7227C59.9375 51.3398 59.25 50.7578 58.75 49.9766C58.25 49.1875 58 48.1797 58 46.9531H61.8203C61.8203 47.8281 61.9961 48.4258 62.3477 48.7461C62.707 49.0664 63.1914 49.2266 63.8008 49.2266C64.4258 49.2266 64.9141 49.0117 65.2656 48.582C65.625 48.1445 65.8047 47.5352 65.8047 46.7539V35Z" strokeWidth=".6" />
            <path d="M84.0039 52.0625H72.4258V35H84.0039V38.082H76.2461V41.8086H82.832V44.7852H76.2461V48.9922H84.0039V52.0625Z" strokeWidth=".6" />
            <path d="M92.4062 35C94.3828 35 95.9414 35.4414 97.082 36.3242C98.2227 37.1992 98.793 38.4805 98.793 40.168C98.793 41.4414 98.5195 42.4531 97.9727 43.2031C97.4336 43.9531 96.707 44.5391 95.793 44.9609L99.4023 51.8867V52.0625H95.3242L92.2305 45.957H89.7227V52.0625H85.9141V35H92.4062ZM92.4062 38.082H89.7227V42.875H92.3945C93.2539 42.875 93.8984 42.6641 94.3281 42.2422C94.7578 41.8125 94.9727 41.2305 94.9727 40.4961C94.9727 39.7461 94.7617 39.1562 94.3398 38.7266C93.918 38.2969 93.2734 38.082 92.4062 38.082Z" strokeWidth=".6" />
            <path d="M112.902 52.0625H101.324V35H112.902V38.082H105.145V41.8086H111.73V44.7852H105.145V48.9922H112.902V52.0625Z" strokeWidth=".6" />
            <path d="M119.781 35L123.895 47.0117L127.996 35H132.988V52.0625H129.156V47.8438L129.531 39.6758L125.172 52.0625H122.605L118.246 39.6875L118.621 47.8438V52.0625H114.801V35H119.781Z" strokeWidth=".6" />
            <path d="M141.895 42.4766L145.234 35H149.371L143.828 45.9102V52.0625H139.973V45.9102L134.418 35H138.566L141.895 42.4766Z" strokeWidth=".6" />
            <path d="M163.633 35H167.43V46.7539C167.43 47.8945 167.172 48.8789 166.656 49.707C166.148 50.5352 165.457 51.1758 164.582 51.6289C163.707 52.0742 162.723 52.2969 161.629 52.2969C160.512 52.2969 159.516 52.1055 158.641 51.7227C157.766 51.3398 157.078 50.7578 156.578 49.9766C156.078 49.1875 155.828 48.1797 155.828 46.9531H159.648C159.648 47.8281 159.824 48.4258 160.176 48.7461C160.535 49.0664 161.02 49.2266 161.629 49.2266C162.254 49.2266 162.742 49.0117 163.094 48.582C163.453 48.1445 163.633 47.5352 163.633 46.7539V35Z" strokeWidth=".6" />
            <path d="M170.266 50.2695C170.266 49.7148 170.453 49.2539 170.828 48.8867C171.203 48.5195 171.699 48.3359 172.316 48.3359C172.934 48.3359 173.426 48.5195 173.793 48.8867C174.168 49.2539 174.355 49.7148 174.355 50.2695C174.355 50.8164 174.168 51.2734 173.793 51.6406C173.426 52.0078 172.934 52.1914 172.316 52.1914C171.699 52.1914 171.203 52.0078 170.828 51.6406C170.453 51.2734 170.266 50.8164 170.266 50.2695Z" strokeWidth=".6" />

            <path d="M50.9805 91.2344L55.0938 103.246L59.1953 91.2344H64.1875V108.297H60.3555V104.078L60.7305 95.9102L56.3711 108.297H53.8047L49.4453 95.9219L49.8203 104.078V108.297H46V91.2344H50.9805Z" strokeWidth=".6" />
            <path d="M72.1797 105.59C72.7656 105.59 73.2344 105.43 73.5859 105.109C73.9375 104.789 74.1172 104.359 74.125 103.82H77.5586C77.5508 104.75 77.3125 105.57 76.8438 106.281C76.375 106.984 75.7383 107.535 74.9336 107.934C74.1367 108.332 73.2422 108.531 72.25 108.531C70.9297 108.531 69.832 108.25 68.957 107.688C68.082 107.117 67.4258 106.348 66.9883 105.379C66.5586 104.41 66.3438 103.32 66.3438 102.109V101.816C66.3438 100.605 66.5586 99.5156 66.9883 98.5469C67.4258 97.5781 68.0781 96.8086 68.9453 96.2383C69.8203 95.668 70.9141 95.3828 72.2266 95.3828C73.2812 95.3828 74.207 95.5859 75.0039 95.9922C75.8008 96.3906 76.4219 96.9688 76.8672 97.7266C77.3203 98.4766 77.5508 99.375 77.5586 100.422H74.125C74.1172 99.8516 73.9492 99.3594 73.6211 98.9453C73.293 98.5312 72.8047 98.3242 72.1562 98.3242C71.5469 98.3242 71.0859 98.4883 70.7734 98.8164C70.4688 99.1445 70.2617 99.5742 70.1523 100.105C70.0508 100.629 70 101.199 70 101.816V102.109C70 102.734 70.0508 103.312 70.1523 103.844C70.2617 104.367 70.4727 104.789 70.7852 105.109C71.0977 105.43 71.5625 105.59 72.1797 105.59Z" strokeWidth=".6" />
            <path d="M87.0039 91.2344H90.2266L92.8281 102.836L94.9492 91.2344H98.7227L95.0312 108.297H91.1523L88.6094 97.5156L86.0781 108.297H82.1992L78.4961 91.2344H82.2812L84.4141 102.836L87.0039 91.2344Z" strokeWidth=".6" />
            <path d="M104.535 108.297H100.738V91.2344H104.535V108.297Z" strokeWidth=".6" />
            <path d="M118.75 108.297H107.617V91.2344H111.426V105.227H118.75V108.297Z" strokeWidth=".6" />
            <path d="M131.781 108.297H120.648V91.2344H124.457V105.227H131.781V108.297Z" strokeWidth=".6" />
            <path d="M137.723 108.297H133.926V91.2344H137.723V108.297Z" strokeWidth=".6" />
            <path d="M143.488 108.297H139.422L145.75 91.2344H149.289L155.641 108.297H151.574L150.496 104.992H144.555L143.488 108.297ZM145.562 101.91H149.5L147.52 95.8867L145.562 101.91Z" strokeWidth=".6" />
            <path d="M162.074 91.2344L166.188 103.246L170.289 91.2344H175.281V108.297H171.449V104.078L171.824 95.9102L167.465 108.297H164.898L160.539 95.9219L160.914 104.078V108.297H157.094V91.2344H162.074Z" strokeWidth=".6" />
            <path d="M186.941 103.785C186.941 103.41 186.859 103.086 186.695 102.812C186.539 102.539 186.234 102.281 185.781 102.039C185.328 101.789 184.66 101.52 183.777 101.23C182.754 100.902 181.805 100.508 180.93 100.047C180.055 99.5859 179.352 99.0156 178.82 98.3359C178.289 97.6484 178.023 96.8008 178.023 95.793C178.023 94.8242 178.297 93.9805 178.844 93.2617C179.398 92.543 180.156 91.9883 181.117 91.5977C182.086 91.1992 183.191 91 184.434 91C185.699 91 186.805 91.2227 187.75 91.668C188.703 92.1133 189.441 92.7344 189.965 93.5312C190.496 94.3203 190.762 95.2383 190.762 96.2852H186.953C186.953 95.6211 186.742 95.0781 186.32 94.6562C185.898 94.2344 185.246 94.0234 184.363 94.0234C183.512 94.0234 182.875 94.1992 182.453 94.5508C182.039 94.9023 181.832 95.3281 181.832 95.8281C181.832 96.3516 182.113 96.7891 182.676 97.1406C183.246 97.4922 184.043 97.8281 185.066 98.1484C186.926 98.7188 188.336 99.4414 189.297 100.316C190.266 101.191 190.75 102.34 190.75 103.762C190.75 105.277 190.184 106.453 189.051 107.289C187.918 108.117 186.402 108.531 184.504 108.531C183.645 108.531 182.801 108.422 181.973 108.203C181.145 107.977 180.395 107.633 179.723 107.172C179.051 106.703 178.512 106.109 178.105 105.391C177.707 104.672 177.508 103.816 177.508 102.824H181.328C181.328 103.824 181.609 104.527 182.172 104.934C182.742 105.332 183.52 105.531 184.504 105.531C185.355 105.531 185.973 105.363 186.355 105.027C186.746 104.684 186.941 104.27 186.941 103.785Z" strokeWidth=".6" />

            <path d="M135.718 72.1175C135.722 71.6253 135.587 71.2317 135.314 70.9367C135.047 70.6417 134.638 70.4922 134.087 70.488C133.589 70.4843 133.19 70.6044 132.889 70.8482C132.594 71.0921 132.445 71.3986 132.442 71.7677L130.263 71.7514C130.267 71.2416 130.431 70.7653 130.757 70.3224C131.083 69.8737 131.539 69.5138 132.127 69.2428C132.721 68.9719 133.42 68.8394 134.222 68.8455C135.294 68.8535 136.177 69.1326 136.87 69.6827C137.564 70.2329 137.906 71.0558 137.898 72.1514L137.866 76.3437C137.86 77.2109 137.978 77.9002 138.22 78.4118L138.219 78.5613L135.986 78.5445C135.889 78.3211 135.812 78.0188 135.756 77.6375C135.478 77.9342 135.128 78.1865 134.704 78.3943C134.287 78.6021 133.794 78.7038 133.225 78.6996C132.604 78.6949 132.052 78.5677 131.567 78.318C131.083 78.0683 130.701 77.7285 130.423 77.2987C130.151 76.8689 130.017 76.3815 130.021 75.8366C130.029 74.8288 130.413 74.0582 131.172 73.5249C131.932 72.9915 133.007 72.73 134.395 72.7404L135.714 72.7503L135.718 72.1175ZM133.669 76.9714C134.161 76.9751 134.584 76.864 134.937 76.6381C135.291 76.4064 135.542 76.1475 135.69 75.8615L135.703 74.095L134.543 74.0863C133.77 74.0805 133.186 74.2197 132.791 74.5038C132.402 74.7822 132.206 75.1645 132.202 75.6508C132.2 76.0258 132.323 76.3402 132.573 76.5941C132.829 76.8421 133.195 76.9679 133.669 76.9714Z" strokeWidth=".6" />
            <path d="M148.458 73.9797C148.451 74.9172 148.304 75.7481 148.017 76.4726C147.73 77.197 147.31 77.7652 146.756 78.177C146.208 78.5889 145.53 78.7919 144.721 78.7858C144.147 78.7815 143.647 78.6723 143.221 78.4581C142.8 78.2382 142.439 77.9337 142.138 77.5447L142.024 78.5898L140.056 78.575L140.157 65.0754L142.337 65.0918L142.3 69.9959C142.595 69.6524 142.946 69.3884 143.352 69.204C143.763 69.0137 144.239 68.9206 144.778 68.9246C145.598 68.9308 146.282 69.141 146.83 69.5553C147.377 69.9695 147.786 70.5439 148.056 71.2784C148.332 72.007 148.466 72.8459 148.459 73.7951L148.458 73.9797ZM146.279 73.7788C146.284 73.2222 146.223 72.7119 146.097 72.2481C145.978 71.7843 145.767 71.4136 145.464 71.1359C145.167 70.8524 144.753 70.7087 144.219 70.7047C143.727 70.701 143.325 70.8064 143.013 71.0208C142.701 71.2353 142.458 71.5235 142.286 71.8855L142.256 75.7702C142.424 76.1347 142.662 76.4295 142.971 76.6545C143.285 76.8795 143.692 76.9939 144.19 76.9976C144.711 77.0015 145.122 76.8698 145.423 76.6025C145.724 76.3352 145.941 75.9736 146.073 75.5175C146.205 75.0556 146.274 74.5376 146.278 73.9634L146.279 73.7788Z" strokeWidth=".6" />
            <path d="M149.812 73.8053C149.819 72.8795 150.001 72.0547 150.358 71.3308C150.715 70.601 151.224 70.0277 151.883 69.6107C152.548 69.1938 153.344 68.9889 154.27 68.9958C155.207 69.0028 156.005 69.2198 156.664 69.6466C157.323 70.0734 157.823 70.6543 158.163 71.3893C158.509 72.1185 158.679 72.946 158.672 73.8717L158.67 74.0563C158.663 74.9762 158.481 75.801 158.124 76.5308C157.773 77.2547 157.265 77.8252 156.6 78.2421C155.94 78.659 155.145 78.864 154.213 78.857C153.282 78.85 152.486 78.6331 151.827 78.2063C151.168 77.7794 150.666 77.2014 150.32 76.4723C149.974 75.7372 149.804 74.9098 149.811 73.9899L149.812 73.8053ZM151.991 74.0062C151.986 74.5687 152.059 75.0849 152.208 75.5547C152.362 76.0188 152.606 76.3927 152.937 76.6765C153.269 76.9602 153.699 77.1041 154.226 77.108C154.748 77.1119 155.174 76.9745 155.504 76.6957C155.84 76.417 156.086 76.0467 156.242 75.585C156.403 75.1175 156.486 74.6024 156.491 74.04L156.492 73.8554C156.496 73.3046 156.421 72.7943 156.266 72.3244C156.117 71.8545 155.877 71.4747 155.545 71.1851C155.213 70.8955 154.784 70.7487 154.256 70.7448C153.735 70.7409 153.306 70.8812 152.97 71.1658C152.64 71.4505 152.394 71.8265 152.232 72.2941C152.076 72.7617 151.996 73.2709 151.992 73.8216L151.991 74.0062Z" strokeWidth=".6" />
            <path d="M166.177 69.2609L168.357 69.2772L168.285 78.7867L166.229 78.7713L166.183 77.8129C165.882 78.1681 165.511 78.4437 165.07 78.6396C164.629 78.8355 164.106 78.9312 163.503 78.9267C162.565 78.9196 161.809 78.6386 161.233 78.0834C160.657 77.5283 160.374 76.6209 160.383 75.3612L160.429 69.2178L162.609 69.2341L162.563 75.3951C162.558 76.0572 162.704 76.5153 163.001 76.7695C163.298 77.0178 163.651 77.1435 164.061 77.1466C164.6 77.1507 165.035 77.0572 165.364 76.8663C165.7 76.6696 165.954 76.4049 166.126 76.0722L166.177 69.2609Z" strokeWidth=".6" />
            <path d="M174.892 70.9347L173.205 70.9221L173.166 76.1426C173.162 76.5762 173.251 76.861 173.432 76.9972C173.612 77.1274 173.855 77.1937 174.16 77.196C174.306 77.1971 174.447 77.1894 174.582 77.1728C174.717 77.1562 174.825 77.1395 174.907 77.1225L174.895 78.8012C174.719 78.8584 174.513 78.9067 174.278 78.946C174.044 78.9852 173.774 79.0037 173.469 79.0014C172.737 78.9959 172.138 78.7893 171.672 78.3815C171.207 77.9678 170.977 77.2688 170.985 76.2845L171.025 70.9057L169.496 70.8942L169.508 69.2859L171.037 69.2974L171.055 66.9683L173.234 66.9847L173.217 69.3137L174.904 69.3264L174.892 70.9347Z" strokeWidth=".6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M93 75H74V70H93V75Z"  strokeWidth=".6"/>
<path d="M92.9004 64.3433L107.113 72.1581L92.9004 79.9728V64.3433Z" strokeWidth=".6"/>
          </svg>
          

        </div>
      </a>
      <a href="#projects">
        <div className="bottom">
          <div className="intro-text">
            <h1 className="hide">
              <span className="text">Full-Stack</span>
            </h1>
            <h1 className="hide">
              <span className="text">Software</span>
            </h1>
            <h1 className="hide">
              <span className="text">Engineer</span>
            </h1>
            <h1 className="hide projects">
              <span className="text">Projects</span>
            </h1>
          </div>
        </div>
      </a>
    </div>

  )
}

export default Splash
